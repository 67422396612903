import { Cocktail } from "@prisma/client";
import Link from "next/link";
import CocktailImage from "../CocktailImage";

type CocktailProps = {
    cocktail: Cocktail;
};

const CocktailCard = ({ cocktail }: CocktailProps) => {
    return (
        <Link href={`/cocktail/${cocktail.id}`}>
            <div className="aspect-[64/96] rounded-xl bg-gray-50 border border-gray-100 text-center drop-shadow">
                <div className="drop-shadow-sm">
                    <CocktailImage
                        glass={cocktail.glass}
                        backgroundColour={"transparent"}
                        liquidColour={cocktail.liquidColour}
                    />
                </div>
                <span className="block p-2">{cocktail.name}</span>
            </div>
        </Link>
    );
};

export default CocktailCard;
