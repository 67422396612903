import Appbar from "@/components/Appbar";

type DefaultProps = {
    children: React.ReactNode;
};

const DefaultLayout = ({ children }: DefaultProps) => {
    return (
        <div className="pb-24">
            <Appbar />
            <div className="px-5 max-w-6xl mx-auto">{children}</div>
        </div>
    );
};

export default DefaultLayout;
