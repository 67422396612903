import Link from "next/link";
import { useRouter } from "next/router";
import { FaCocktail, FaCompass, FaStar } from "react-icons/fa";
import { MdMenu } from "react-icons/md";

type AppbarItemProps = {
    icon: React.ReactNode;
    label: string;
    url: string;
};

const AppbarItem = ({ icon, label, url }: AppbarItemProps) => {
    const router = useRouter();

    return (
        <div className="flex-1 group">
            <Link
                href={url}
                className={`flex items-end justify-center text-center mx-auto w-full border-b-2 border-transparent ${
                    router.asPath === url
                        ? "text-cyan-500 border-cyan-500"
                        : "text-gray-600 group-hover:text-cyan-500 group-hover:border-cyan-500"
                }`}
            >
                <span className="flex flex-col items-center px-1">
                    <span className="text-2xl mb-2">{icon}</span>
                    <span className="block text-sm mb-2">{label}</span>
                </span>
            </Link>
        </div>
    );
};

export default function Appbar() {
    return (
        <div className="fixed bottom-0 left-0 w-full p-4 bg-gray-100 drop-shadow-lg z-50">
            <div className="flex max-w-4xl mx-auto">
                <AppbarItem label="Explore" url="/" icon={<FaCompass />} />
                <AppbarItem label="Bar" url="/bar" icon={<FaCocktail />} />
                <AppbarItem
                    label="Favourites"
                    url="/favourites"
                    icon={<FaStar />}
                />
                <AppbarItem label="Menu" url="/menu" icon={<MdMenu />} />
            </div>
        </div>
    );
}
