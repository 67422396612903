import Link from "next/link";
import Button from "./Button";

type CardGridProps = {
    title: string;
    button?: string;
    to?: string;

    children?: React.ReactNode;
};

export default function CardGrid({
    title,
    children,
    button,
    to,
}: CardGridProps) {
    return (
        <div>
            <h2 className="text-2xl font-bold text-gray-700">{title}</h2>
            <div className="my-4 grid gap-4 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
                {children}
            </div>

            {button && (
                <div className="w-full text-center">
                    {to ? (
                        <Link href={to}>
                            <Button>{button}</Button>
                        </Link>
                    ) : (
                        <Button>{button}</Button>
                    )}
                </div>
            )}
        </div>
    );
}
