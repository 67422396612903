type ButtonProps = {
    children?: React.ReactNode;
    onClick?: () => void;
};

export default function Button({ children, onClick }: ButtonProps) {
    return (
        <button
            onClick={onClick}
            className="pointer-events-auto rounded-md bg-indigo-600 py-2 px-8 text-[0.8125rem] font-semibold leading-5 text-white hover:bg-indigo-500"
        >
            {children}
        </button>
    );
}
