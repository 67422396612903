import CardGrid from "@/components/CardGrid";
import CocktailCard from "@/components/Cards/Cocktail";
import ListCard from "@/components/Cards/List";
import SpiritCard from "@/components/Cards/Spirit";
import DefaultLayout from "@/layouts/DefaultLayout";
import prisma from "@/lib/prisma";
import { ListWithCocktails } from "@/types";
import { Cocktail } from "@prisma/client";
import type { GetServerSideProps } from "next";

type HomePageProps = {
    cocktailLists: ListWithCocktails[];
    popularCocktails: Cocktail[];
    spirits: string[];
    latestCocktails: Cocktail[];
};

const EmptyCocktailCard = () => (
    <div className="aspect-[64/96] rounded-xl bg-slate-50 border border-gray-100 text-center drop-shadow-sm flex justify-center items-center text-gray-600">
        <span className="block p-2">No Cocktails Found</span>
    </div>
);

const HomePage = ({
    cocktailLists,
    popularCocktails,
    spirits,
    latestCocktails,
}: HomePageProps) => {
    return (
        <DefaultLayout>
            <h1 className="pb-8 pt-16 text-4xl font-extrabold">Explore</h1>

            {/* Cocktail Lists */}
            {cocktailLists.length > 0 && (
                <div className="mb-4 flex gap-4 overflow-x-scroll">
                    {cocktailLists.map((list) => (
                        <ListCard key={list.id} list={list} />
                    ))}
                </div>
            )}

            {/* Popular Cocktails */}
            <div className="py-4">
                <CardGrid
                    title="Popular"
                    button="Popular Cocktails"
                    to="/cocktails/popular"
                >
                    {popularCocktails.map((cocktail) => (
                        <CocktailCard key={cocktail.id} cocktail={cocktail} />
                    ))}
                    {popularCocktails.length === 0 && <EmptyCocktailCard />}
                </CardGrid>
            </div>

            {/* Spirits List */}
            <div className="py-4">
                <CardGrid title="Spirits">
                    {spirits.map((s) => (
                        <SpiritCard key={s} name={s} />
                    ))}
                </CardGrid>
            </div>

            {/* Latest Cocktails */}
            <div className="py-4">
                <CardGrid
                    title="Latest"
                    button="Latest Cocktails"
                    to="/cocktails/latest"
                >
                    {latestCocktails.map((cocktail) => (
                        <CocktailCard key={cocktail.id} cocktail={cocktail} />
                    ))}
                    {latestCocktails.length === 0 && <EmptyCocktailCard />}
                </CardGrid>
            </div>
        </DefaultLayout>
    );
};

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (
    _context
) => {
    // TODO: Actually count some form of popularity
    const popularCocktails = await prisma.cocktail.findMany({ take: 6 });

    const latestCocktails = await prisma.cocktail.findMany({
        orderBy: {
            dateAdded: "desc",
        },
        take: 6,
    });

    const lists = await prisma.list.findMany({
        include: {
            cocktails: {
                take: 1,
                orderBy: {
                    position: "desc",
                },
                include: {
                    cocktail: true,
                },
            },
        },
    });

    return {
        props: {
            cocktailLists: lists,
            popularCocktails: popularCocktails,
            spirits: ["Vodka", "Rum", "Tequila", "Whiskey", "Gin", "Liqueur"],
            latestCocktails: latestCocktails,
        },
    };
};

export default HomePage;
