import { ListWithCocktails } from "@/types";
import Link from "next/link";
import CocktailImage from "../CocktailImage";

type ListProps = {
    list: ListWithCocktails;
};

const ListCard = ({ list }: ListProps) => {
    return (
        <Link href={`/cocktails/${list.id}`}>
            <div className="shrink-0 w-64 h-96 rounded-xl bg-gray-50 border border-gray-100 text-center drop-shadow">
                <div className="drop-shadow-sm">
                    <CocktailImage
                        backgroundColour={"transparent"}
                        glass={list.cocktails[0].cocktail.glass}
                        liquidColour={list.cocktails[0].cocktail.liquidColour}
                    />
                </div>
                <span className="block p-2">{list.name}</span>
            </div>
        </Link>
    );
};

export default ListCard;
