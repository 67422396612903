type SpiritProps = {
    name: string;
};

const SpiritCard = ({ name }: SpiritProps) => {
    return (
        <div className="aspect-[64/32] rounded-xl bg-slate-50 border border-gray-100 flex items-center justify-center drop-shadow-md">
            <span className="font-bold text-base">{name}</span>
        </div>
    );
};

export default SpiritCard;
